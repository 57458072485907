<template>
    <v-card outlined>
        <v-card-text>
            <v-row wrap ro align="center">
                <v-col cols="12">
                    <form autocomplete="nope" @submit.prevent="postData">
                        <v-container grid-list-md fluid>
                            <v-row dense align="center">
                                <v-col cols="6">
                                    <v-autocomplete
                                        v-model="servicio"
                                        v-validate="'required'"
                                        label="Producto"
                                        :items="listaAlmacen"
                                        data-vv-as="marca"
                                        :error-messages="errors.collect('servicio')"
                                        data-vv-name="servicio"
                                        required
                                        item-text="product"
                                        item-value="id"
                                        return-object
                                    >
                                        <template v-slot:item="data">
                                            <v-chip small color="secondaryTheme" class="caption" dark>{{ data.item.category }}</v-chip>
                                            <span class="caption">{{ data.item.product }} - S/ {{ data.item.price }}</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-autocomplete v-model="quantity" :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" label="Unidades" />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field v-model="itemTotalProduct" prefix="S/." filled label="Sub total" readonly />
                                </v-col>
                                <v-col cols="12" sm="2" class="text-center">
                                    <v-btn block color="secondaryTheme" dark type="submit">agregar</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </form>
                </v-col>
                <v-col cols="12" class="grey lighten-5">
                    <v-data-table :headers="headers" :items="listaServicios" class="elevation-1" :items-per-page="20" item-key="timestamp">
                        <template v-slot:[`item.action`]="{ item }">
                            <v-icon color="primary" small @click="deleteItem(item)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { format } from 'date-fns'

export default {
    props: ['lista', 'service'],
    $_veeValidate: {
        validator: 'new'
    },
    filters: {},
    data: () => ({
        servicio: null,
        isSelect: false,
        quantity: 1,
        time: format(new Date(), 'HH:mm'),
        listaServicios: [],
        selectCategory: [
            { id_category: 1, category: 'BAZAR' },
            { id_category: 2, category: 'CAFETIN' },
            { id_category: 3, category: 'SERVICIOS' },
            { id_category: 4, category: 'USO' },
            { id_category: 5, category: 'ALQUILER' }
        ],
        headers: [
            { text: 'Actions', value: 'action', sortable: false },
            { text: 'Descripción', value: 'product' },
            { text: 'Categoria', value: 'category' },
            { text: 'P. UNIT (S/)', value: 'price' },
            { text: 'CANT', value: 'unidades' },
            { text: 'SUBTOTAL', value: 'subTotal' }
        ]
    }),
    computed: {
        ...mapState('ventas', ['datos']),
        ...mapGetters('productos', ['listaAlmacen']),
        itemTotalProduct() {
            if (this.servicio) {
                return parseFloat(this.servicio.price) * parseFloat(this.quantity)
            }
            return null
        }
    },

    methods: {
        postData() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    this.listaServicios.push({
                        ...this.servicio,
                        unidades: this.quantity,
                        subTotal: this.itemTotalProduct
                    })
                    this.$store.commit('ventas/UPDATE_ALMACEN', this.listaServicios)
                    this.servicio = null
                    this.quantity = 1
                    this.time = format(new Date(), 'HH:mm')
                }
            })
        }
    }
}
</script>
<style></style>
